/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


.header {

    .clearHeader {

        > .row {
            min-height: 120px;
        }

        #logo {
            width: 160px;
            height: 110px;
            background-size: 160px 110px;
            @include transition(all 0.3s ease-in-out);
        }
    }

    .darkHeader {

        > .row {
            min-height: 120px;
        }

        #logo {
            width: 160px;
            height: 110px;
            background-size: 160px 110px;
            @include transition(all 0.3s ease-in-out);
        }
    }
}


#content {height:auto;}
